import * as React from "react";
import { graphql } from "gatsby";

import { FullLayout } from "../components/layout";
import { Seo } from "../components/seo";

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <FullLayout>
      <Seo title={`404: Not Found | ${siteTitle}`} />
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </FullLayout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
